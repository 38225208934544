import React, {
  useCallback, useState, useMemo, useEffect, useContext, useRef,
} from 'react';
import {
  Paper, Table, TableHead, TableContainer,
  TableRow, TableCell, TableBody,
  Typography, Grid,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { RouteComponentProps } from 'react-router-dom';
import { setProgressBarStatus as SetProgressBarStatus } from '../../redux/actions/progressBar';
import useStyles from './styles';
import useDebounce from '../../hooks/useDebounce';
import CustomTablePagination from '../CustomTablePagination';
import ConfirmDelete from '../ConfirmDelete';
import CustomButton from '../CustomButton';
import TableHeadCell from '../TableHeadCell';
import SelectedActionsBar from '../SelectedActionsBar';
import SearchBar from '../SearchBar';
import Checkbox from '../CustomCheckbox';
import CustomTableScroll from '../CustomTableScroll';
import { isSelected } from '../../utils/table';
import { ClientFields, ClientType as Client } from '../../types/Client';
import { ALL_CLIENTS, REMOVE_CLIENTS } from '../../graphql/queries/Clients';
import { initialState, SearchSortContext } from '../../context/SearchSortContext';
import RowsPerPageSelect from '../RowsPerPageSelect/RowsPerPageSelect';
import { loadExport, loadExportAll } from '../../services/export';
import downloadDocument from '../../utils/files';

const searchBarPlaceholder: string = 'Search сlients';

type Props = {
  setProgressBarStatus: typeof SetProgressBarStatus,
} & RouteComponentProps;

const Clients: React.FC<Props> = ({ history, match, setProgressBarStatus }) => {
  const classes = useStyles();
  const { dispatch, state } = useContext(SearchSortContext);
  const {
    orderBy, order, contextSearch, rowsPerPageAmount, currentPage,
  } = state.clients;
  const [cursor, setCursor] = useState<{ limit: number, offset: number }>({
    limit: rowsPerPageAmount, offset: currentPage * rowsPerPageAmount,
  });
  const [search, setSearch] = useState<string>(contextSearch);
  const { enqueueSnackbar } = useSnackbar();
  const [selected, setSelected] = useState<Array<Client['builder_id']>>([]);
  const selectedLength = selected.length;
  const [sort, setSort] = useState<{ sortBy: ClientFields | string, direction: 'asc' | 'desc' }>({ sortBy: orderBy, direction: order });
  const [clientToRemove, setClientToRemove] = useState<boolean>(false);
  const debouncedSearch = useDebounce(search, 1000);

  const { loading, error, data } = useQuery<{ builderList: Paginated<Client> }>(ALL_CLIENTS, {
    variables: {
      limit: cursor.limit,
      offset: cursor.offset,
      search: debouncedSearch,
      sortBy: sort.sortBy,
      direction: sort.direction,
    },
    fetchPolicy: 'network-only',
  });

  const [exportAllLoading, setExportAllLoading] = useState<boolean>(false);

  const handleShowAllClick = () => {
    setSort({
      direction: 'desc',
      sortBy: 'modified_on',
    });
    setSearch('');
    dispatch({ type: 'RESET_STATE' });
  };
  const [removeClients] = useMutation(REMOVE_CLIENTS, {
    refetchQueries: [{
      query: ALL_CLIENTS,
      variables: {
        limit: cursor.limit,
        offset: cursor.offset,
        search: debouncedSearch,
        sortBy: sort.sortBy,
        direction: sort.direction,
      },
    }],
  });

  useEffect(() => {
    setProgressBarStatus(loading);
  }, [loading, error, setProgressBarStatus]);

  const handleChangePage = useCallback((_e: unknown, nPage: number) => {
    setCursor(prevCursor => ({ ...prevCursor, offset: prevCursor.limit * nPage }));
    dispatch({
      type: 'CHANGE_CLIENT',
      payload: {
        currentPage: nPage,
      },
    });
  }, [dispatch]);

  const handleChangeRowsPerPage = useCallback(e => {
    setCursor({ offset: 0, limit: e.target.value });
    dispatch({
      type: 'CHANGE_CLIENT',
      payload: {
        rowsPerPageAmount: e.target.value,
        currentPage: 0,
      },
    });
  }, [dispatch]);

  const page = useMemo(
    () => Math.ceil(cursor.offset / cursor.limit),
    [cursor.limit, cursor.offset],
  );

  const handleClick = (
    event: React.SyntheticEvent,
    id: Client['builder_id'],
  ) => {
    event.preventDefault();

    const selectedIndex = selected.indexOf(id);
    let newSelected: Array<Client['builder_id']> = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = data ? data.builderList.list.map(i => i.builder_id) : [];
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  function handleExport() {
    if (selectedLength !== 0) {
      loadExport(selected, 'clients')
        .then(response => {
          const contentType = response.headers['Content-Type'];
          downloadDocument(contentType, 'Clients', response.data);
        });
    }
  }

  function handleExportAll() {
    setExportAllLoading(true);
    loadExportAll('clients').then(response => {
      setExportAllLoading(false);
      const contentType = response.headers['Content-Type'];
      downloadDocument(contentType, 'Clients', response.data);
    });
  }

  const handlePrint = () => {
    if (selectedLength !== 0) { }
  };
  const handleClientDelete = () => {
    if (selectedLength !== 0) {
      removeClients({
        variables: {
          builders: selected,
        },
      }).then(() => {
        setSelected([]);
        enqueueSnackbar('Removed', { variant: 'success' });
      })
        .catch(() => {
          enqueueSnackbar('An error occurred', { variant: 'error' });
        });
    }
  };

  const handleSortBy = (property: ClientFields) => {
    const isDesc = sort.sortBy === property && sort.direction === 'desc';
    const direction = isDesc ? 'asc' : 'desc';
    setSort({
      direction,
      sortBy: property,
    });
    setCursor({ limit: rowsPerPageAmount, offset: 0 });
    dispatch({
      type: 'CHANGE_CLIENT',
      payload: {
        orderBy: property,
        order: direction,
        currentPage: 0,
      },
    });
  };

  useEffect(() => () => {
    const pageNameIndex = 1;
    const pathElements = history.location.pathname.split('/');
    const pathname = pathElements[pageNameIndex];
    if (pathElements.length === 3) {
      return;
    }
    if ((pathname !== 'clients')) {
      dispatch({
        type: 'RESET_STATE',
      });
    }
  }, [dispatch, history.location.pathname]);

  const handleSearch = useCallback((value:string) => {
    dispatch({
      type: 'CHANGE_CLIENT',
      payload: { contextSearch: value, currentPage: 0 },
    });
    setSearch(value);
    setCursor({ limit: rowsPerPageAmount, offset: 0 });
  }, [dispatch, rowsPerPageAmount]);

  const isStateInitial = JSON.stringify({
    contextSearch: state.clients.contextSearch,
    order: state.clients.order,
    orderBy: state.clients.orderBy,
  }) === JSON.stringify({
    contextSearch: initialState.clients.contextSearch,
    order: initialState.clients.order,
    orderBy: initialState.clients.orderBy,
  });

  const tableContainer = useRef<HTMLDivElement>(null);

  if (!data) return null;
  if (error) {
    return (
      <Typography variant="h5">
        Error :( -
        {' '}
        {error}
      </Typography>
    );
  }

  return (
    <div>
      <Grid container justify="space-between">
        <Grid container spacing={8}>
          <Grid item>
            <CustomButton variant="orange" color="primary" style={{ marginBottom: 25 }} onClick={() => history.push(`${match.path}/add`)}> Add Client</CustomButton>
          </Grid>
          {!isStateInitial && (
            <Grid item>
              <CustomButton variant="orange" color="primary" style={{ marginBottom: 25 }} onClick={handleShowAllClick}> Show all </CustomButton>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid>
        <SearchBar
          placeholder={searchBarPlaceholder}
          defaultSearchValue={search}
          onChange={handleSearch}
        />
      </Grid>
      <Paper className={classes.paper} style={{ marginTop: 33 }}>
        <SelectedActionsBar
          onDelete={() => { setClientToRemove(true); }}
          onExport={handleExport}
          onExportAll={handleExportAll}
          onPrint={handlePrint}
          selectedLength={selectedLength}
          exportAllLoading={exportAllLoading}
        />
        <Typography style={{ marginLeft: '23px' }} variant="h5">Clients</Typography>
        <div className={classes.tableWrapper}>
          <Grid container alignContent="center" justify="flex-end">
            <RowsPerPageSelect
              rowsPerPageOptions={[25, 50, 100, 150]}
              rowsPerPage={cursor.limit}
              onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })}
            />
          </Grid>
          <TableContainer ref={tableContainer} className={classes.tableContainer}>
            <CustomTableScroll getTableContainer={() => tableContainer} columns={9} />
            <Table className={classes.tableRoot}>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={selectedLength > 0
                      && selectedLength < data.builderList.list.length}
                      checked={selectedLength === data.builderList.list.length}
                      onChange={onSelectAllClick}
                      inputProps={{ 'aria-label': 'Select all locks' }}
                    />
                  </TableCell>
                  <TableHeadCell sortBy={sort.sortBy} sortDirection={sort.direction} value="name" label="Client Name" onSortClick={handleSortBy} />
                  <TableHeadCell sortBy={sort.sortBy} sortDirection={sort.direction} value="main_contact" onSortClick={handleSortBy} align="right" />
                  <TableHeadCell sortBy={sort.sortBy} sortDirection={sort.direction} value="contact_position" onSortClick={handleSortBy} align="right" />
                  <TableHeadCell sortBy={sort.sortBy} sortDirection={sort.direction} label="Type" value="is_rental" onSortClick={handleSortBy} align="center" />
                  <TableHeadCell sortBy={sort.sortBy} sortDirection={sort.direction} value="texting_active" onSortClick={handleSortBy} align="center" />
                  <TableHeadCell sortBy={sort.sortBy} sortDirection={sort.direction} value="STI_active" label="STI" onSortClick={handleSortBy} align="center" />
                  <TableHeadCell sortBy={sort.sortBy} sortDirection={sort.direction} value="LASSO_active" label="LASSO" onSortClick={handleSortBy} align="center" />
                  <TableHeadCell sortBy={sort.sortBy} sortDirection={sort.direction} value="requires_opt_in" label="Opt-in" onSortClick={handleSortBy} align="center" />
                  <TableHeadCell sortBy={sort.sortBy} sortDirection={sort.direction} value="enable_photo_gallery_edits" label="Galarry Edits" onSortClick={handleSortBy} align="center" />
                  <TableHeadCell sortBy={sort.sortBy} sortDirection={sort.direction} value="created_by" onSortClick={handleSortBy} align="right" />
                  <TableHeadCell sortBy={sort.sortBy} sortDirection={sort.direction} value="created_on" onSortClick={handleSortBy} align="right" />
                  <TableHeadCell sortBy={sort.sortBy} sortDirection={sort.direction} value="Novihome_active" onSortClick={handleSortBy} align="right" />
                  <TableHeadCell sortBy={sort.sortBy} sortDirection={sort.direction} value="active" onSortClick={handleSortBy} align="right" />


                </TableRow>
              </TableHead>
              <TableBody>
                {data.builderList.list.map((client, index) => {
                  const isItemSelected: boolean = isSelected(
                    client.builder_id,
                    selected,
                  );
                  const isLassoActive = client.builderIntegrations.find(({ integration_id: integrationId, isActive }) => integrationId === 8 && isActive);
                  const isNovihomeActive = client.builderIntegrations.find(({ integration_id: integrationId, isActive }) => integrationId === 9 && isActive);
                  const labelId: string = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      key={client.builder_id.toString()}
                      className={classes.pointer}
                      onClick={() => history.push(`${match.path}/${client.builder_id}`)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onClick={event => {
                            event.stopPropagation();
                            handleClick(event, client.builder_id);
                          }}
                        />
                      </TableCell>
                      <TableCell className={classes.orange} component="th" scope="row">{client.name}</TableCell>
                      <TableCell align="right">{client.main_contact}</TableCell>
                      <TableCell align="right">{client.contact_position}</TableCell>
                      <TableCell align="center">{client.is_rental ? 'Rental' : 'Builder'}</TableCell>
                      <TableCell align="center">{client.texting_active ? 'Yes' : 'No'}</TableCell>
                      <TableCell align="center">{client.STI_active ? 'Yes' : 'No'}</TableCell>
                      <TableCell align="center">{isLassoActive ? 'Yes' : 'No'}</TableCell>
                      <TableCell align="center">{client.requires_opt_in ? 'Yes' : 'No'}</TableCell>
                      <TableCell align="center">{client.enable_photo_gallery_edits ? 'Yes' : 'No'}</TableCell>
                      <TableCell align="right">{client.created_by}</TableCell>
                      <TableCell
                        align="right"
                      >
                        {client.created_on ? new Date(client.created_on).toLocaleDateString() : ''}
                      </TableCell>
                      <TableCell align="center">{isNovihomeActive ? 'Yes' : 'No'}</TableCell>
                      <TableCell align="center">{client.active ? 'Yes' : 'No'}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <CustomTablePagination
            rowsPerPageOptions={[25, 50, 100, 150]}
            rowsTotal={data!.builderList.params.total}
            rowsCount={data!.builderList.list.length}
            rowsPerPage={cursor.limit}
            currentPage={page}
            onChangePage={n => handleChangePage(undefined, n)}
            onChangeRowsPerPage={n => handleChangeRowsPerPage({ target: { value: n } })}
          />
        </div>
      </Paper>
      <ConfirmDelete
        isOpen={clientToRemove}
        onCancel={() => { setClientToRemove(false); }}
        onConfirm={() => { handleClientDelete(); setClientToRemove(false); }}
      />
    </div>
  );
};

export default React.memo(Clients);
