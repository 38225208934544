/*
Misc
custom

Devices
hub.devices.list
hub.device.name.set
hub.device.room.set
hub.device.armed.set
hub.device.force_remove

-- Duplicated
hub.device.setting.value.set
hub.device.settings.list
hub.device.settings.list -- filtered by device ID

Settings
hub.device.setting.value.set
hub.device.settings.list
hub.device.settings.list -- filtered by device ID

Items
hub.items.list
hub.item.value.set
hub.items.list -- filtered by device Id

Rooms -> no need
hub.room.list
hub.room.create
hub.room.name.set
hub.room.order.set
hub.room.get
hub.room.delete
hub.room.all.delete

House Modes
hub.modes.get
hub.modes.current.get
hub.modes.switch
hub.modes.cancel_switch
hub.modes.switch_to_delay_set
hub.modes.alarm_delay_set
hub.modes.notification.set

Hub
hub.info.get
hub.reset
hub.reboot

Plugins
hub.extensions.plugin.run -- Zwave
hub.extensions.plugin.run -- Zigbee
hub.extensions.plugin.run -- 2GIG

Scenes -> no need
hub.scenes.get
hub.scenes.list
hub.scenes.delete
hub.scenes.blocks.list
hub.scenes.enabled.set
hub.scenes.notification.add
hub.scenes.notification.remove
hub.scenes.run
hub.scenes.room.set
hub.scenes.time.list
hub.scenes.trigger.devices.list
*/

export enum CommandEnum {
  HubDevicesList = 'hub.devices.list',
  HubItemsList = 'hub.items.list',
  HubReboot = 'hub.reboot',
  HubNetworkGet = 'hub.network.get',
  HubDeviceCheck = 'hub.device.check',
  DictionaryRemove = 'hub.item.dictionary.value.remove',
  DictionarySet = 'hub.item.dictionary.value.set',
  HubNetworkReconnect = 'hub.network.modem.try_connect',
  HubDeviceSettingList = 'hub.device.settings.list',
  HubDeviceSettingSet = 'hub.device.setting.value.set',
}

export enum InputType {
  Text = 'text',
  ListText = 'list_text',
  MixType = 'mix_type',
  Checkbox = 'checkbox',
  Number = 'number',
  Textarea = 'textarea',
  CustomRunZWave = 'custom:run:zwave',
  CustomRunZigbee = 'custom:run:zigbee',
  CustomRun2GIG = 'custom:run:2gig',
}

export interface InputParams {
  name: string;
  type: InputType;
  required?: boolean;
  default?: string | boolean;
}

export interface CommandModel {
  group?: boolean;
  name: string;
  value: string;
  method?: string;
  customize?: boolean;
  params?: InputParams[];
}

export const CommandList: CommandModel[] = [
  { name: 'Misc', group: true, value: 'misc' },
  {
    name: 'custom',
    value: 'custom',
    customize: true,
    params: [
      { name: 'payload', type: InputType.Textarea, required: true },
    ],
  },

  /*
    Devices
    hub.devices.list
    hub.device.name.set
    hub.device.room.set
    hub.device.armed.set -> ignore
    hub.device.force_remove

    // Duplicated
    hub.device.setting.value.set
    hub.device.settings.list
    hub.device.settings.list -- filtered by device ID
    */
  { name: 'Devices', group: true, value: 'devices' },
  {
    name: 'hub.devices.list',
    value: CommandEnum.HubDevicesList,
  },
  {
    name: 'hub.device.name.set',
    value: 'hub.device.name.set',
    params: [
      { name: '_id', type: InputType.Text, required: true },
      { name: 'name', type: InputType.Text, required: true },
    ],
  },
  {
    name: 'hub.device.armed.set',
    value: 'hub.device.armed.set',
    params: [
      { name: '_id', type: InputType.Text, required: true },
      {
        name: 'armed', type: InputType.Checkbox, required: false, default: false,
      },
    ],
  },
  {
    name: 'hub.device.force_remove',
    value: 'hub.device.force_remove',
    params: [
      { name: '_id', type: InputType.Text, required: true },
    ],
  },

  /*
  Settings
  hub.device.setting.value.set
  hub.device.settings.list
  hub.device.settings.list -- filtered by device ID
  */
  { name: 'Settings', group: true, value: 'settings' },
  {
    name: 'hub.device.setting.value.set',
    value: 'hub.device.setting.value.set',
    params: [
      { name: '_id', type: InputType.Text, required: true },
      {
        name: 'value', type: InputType.Number, required: true,
      },
    ],
  },
  {
    name: 'hub.device.settings.list',
    value: 'hub.device.settings.list',
  },
  {
    name: 'hub.device.settings.list -- filtered by device ID',
    value: 'hub.device.settings.list:deviceIds',
    method: 'hub.device.settings.list',
    params: [
      { name: 'deviceIds', type: InputType.ListText, required: true },
    ],
  },

  /*
  Items => Done
  hub.items.list
  hub.item.value.set
  hub.items.list -- filtered by device Id
  */
  { name: 'Items', group: true, value: 'items' },
  {
    name: 'hub.items.list',
    value: 'hub.items.list',
  },
  {
    name: 'hub.item.value.set',
    value: 'hub.item.value.set',
    params: [
      { name: '_id', type: InputType.Text, required: true },
      { name: 'value', type: InputType.MixType, required: true },
    ],
  },
  {
    name: 'hub.items.list -- filtered by device Id',
    value: 'hub.items.list:deviceIds',
    method: 'hub.items.list',
    params: [
      { name: 'deviceIds', type: InputType.ListText, required: true },
    ],
  },
  /*
  House Modes
  hub.modes.get
  hub.modes.current.get
  hub.modes.switch
  hub.modes.cancel_switch

  // Failed
  hub.modes.switch_to_delay_set
  hub.modes.alarm_delay_set
  hub.modes.notification.set
   */
  { name: 'House Modes', group: true, value: 'house_modes' },
  {
    name: 'hub.modes.get',
    value: 'hub.modes.get',
  },
  {
    name: 'hub.modes.current.get',
    value: 'hub.modes.current.get',
  },
  {
    name: 'hub.modes.switch',
    value: 'hub.modes.switch',
    params: [
      { name: 'modeId', type: InputType.Text, required: true },
    ],
  },
  {
    name: 'hub.modes.cancel_switch',
    value: 'hub.modes.cancel_switch',
  },
  /*
  Hub
  hub.info.get
  hub.reset
  hub.reboot
   */
  { name: 'Hub', group: true, value: 'hub' },
  {
    name: 'hub.info.get',
    value: 'hub.info.get',
  },
  {
    name: 'hub.reset',
    value: 'hub.reset',
    params: [
      {
        name: 'softReset', type: InputType.Checkbox, required: false, default: false,
      },
    ],
  },
  {
    name: 'hub.reboot',
    value: 'hub.reboot',
  },

  /*
  Plugins => Done
  hub.extensions.plugin.run -- Zwave
  hub.extensions.plugin.run -- Zigbee
  hub.extensions.plugin.run -- 2GIG
   */
  { name: 'Plugins', group: true, value: 'plugins' },
  {
    name: 'hub.extensions.plugin.run -- Zwave',
    value: 'hub.extensions.plugin.run:zwave',
    method: 'hub.extensions.plugin.run',
    params: [
      { name: 'script', type: InputType.CustomRunZWave },
    ],
  },
  {
    name: 'hub.extensions.plugin.run -- Zigbee',
    value: 'hub.extensions.plugin.run:zigbee',
    method: 'hub.extensions.plugin.run',
    params: [
      { name: 'script', type: InputType.CustomRunZigbee },
    ],
  },
  {
    name: 'hub.extensions.plugin.run -- 2GIG',
    value: 'hub.extensions.plugin.run:2gig',
    method: 'hub.extensions.plugin.run',
    params: [
      { name: 'script', type: InputType.CustomRun2GIG },
    ],
  },
];

export enum ConnectStatus {
  NotConnect = 'NotConnect',
  Connecting = 'Connecting',
  Connected = 'Connected',
}

export enum EzloResponseMethod {
  Login = 'loginUserMios',
  Registered = 'registered',
}

export enum EzloResponseId {
  LoginUser = 'loginUser',
  Register = 'register',
  UIBroadcast = 'ui_broadcast',
}

export interface DeviceCode {
  name: string,
  key: string,
  code: string,
  mode: string,
}

export interface DeviceInfo {
  id: string,
  name: string,
  reachable: boolean,
  manufacturer: string,
  model: string,
  battery?: string,
}

export enum EzloDeviceCategory {
  DoorLock = 'door_lock'
}

interface EzloDeviceInfo {
  manufacturer: string;
  model: string;
}

export enum DevicePropertyName {
  DOOR_LOCK = 'door_lock',
  USER_CODES = 'user_codes',
  LOCK_OPERATION = 'lock_operation',
  DAILY_USER_CODE_INTERVALS = 'daily_user_code_intervals',
  LIGHT_SWITCH = 'switch',
  BATTERY = 'battery',
  THERMOSTAT_MODE = 'thermostat_mode',
  TEMP = 'temp',
  THEMOSTAT_SET_HEAT = 'thermostat_setpoint_heating',
  THEMOSTAT_SET_COOL = 'thermostat_setpoint_cooling',
}

export interface EzloDevice {
  _id: string;
  batteryPowered: boolean;
  category: string;
  deviceTypeId: string;
  gatewayId: string;
  name: string;
  info: EzloDeviceInfo;
  parentDeviceId: string;
  persistent: boolean;
  reachable: boolean;
  ready: boolean;
  roomId: string;
  security: string;
  status: string;
  subcategory: string;
  type: string;
}

enum Mode {
  enabled = 'enabled',
  disabled = 'disabled',
}

export interface UserCodeValue {
  [key: string]: {
    code: string;
    mode: Mode;
    name: string;
  };
}

export interface DeviceProperty {
  _id: string;
  name: DevicePropertyName;
  deviceId: string;
  value?: UserCodeValue | string | number;
}

export enum DoorLockEnum {
  Unsecured = 'unsecured',
  Secured = 'secured',
  Unknown = 'unknown',
  UnsecuredWithTimeout = 'unsecured_with_timeout',
}

export interface LockProperty {
  battery: number;
  status: DoorLockEnum;
  userCodes: UserCodeValue;
  maxUserCodeKey: number;
  lockTimeout?: number;
  lockTimeoutEnabled?: boolean;
}

export interface NetworkMobile {
  accessTechnology: string;
  frequencyBand: string;
  operator: string;
  signalQuality: {
    level: number;
    rsrp: number;
    rsrq: number;
    rssi: number;
  },
}

/**
 * {
 *   "api": "1.0",
 *   "error": null,
 *   "id": "1732207619423",
 *   "method": "hub.device.settings.list",
 *   "result": {
 *     "settings": [
 *       {
 *         "_id": "673b9466f5fad215178069ed",
 *         "description": {
 *           "text": "Anti-Theft protection enabled. Device'll be locked after exclusion"
 *         },
 *         "deviceId": "673b9465f5fad215178069cc",
 *         "label": {
 *           "text": "Anti-Theft protection enabled"
 *         },
 *         "status": "synced",
 *         "value": false,
 *         "valueDefault": false,
 *         "valueType": "bool"
 *       },
 *       {
 *         "_id": "673b9466f5fad215178069ec",
 *         "description": {
 *           "text": "Inside handle ids that can be managed from door lock configuration"
 *         },
 *         "deviceId": "673b9465f5fad215178069cc",
 *         "enum": {
 *           "1": {
 *             "text": "Handle 1"
 *           }
 *         },
 *         "label": {
 *           "text": "Supported inside handle ids"
 *         },
 *         "status": "synced",
 *         "value": [],
 *         "valueType": "array.token"
 *       },
 *       {
 *         "_id": "673b9466f5fad215178069e8",
 *         "description": {
 *           "text": "Initiate identification diode blinking on device"
 *         },
 *         "deviceId": "673b9465f5fad215178069cc",
 *         "label": {
 *           "text": "Device identify"
 *         },
 *         "status": "synced",
 *         "value": {
 *           "text": "Identify"
 *         },
 *         "valueType": "action"
 *       },
 *       {
 *         "_id": "673b9466f5fad215178069e6",
 *         "description": {
 *           "lang_tag": "zwave_config_descr_144_2065_5032_40",
 *           "text": "Perform a lock factory reset"
 *         },
 *         "deviceId": "673b9465f5fad215178069cc",
 *         "hasSetter": true,
 *         "info": {
 *           "zwave.parameter_number": 40,
 *           "zwave.parameter_size": 1
 *         },
 *         "label": {
 *           "lang_tag": "zwave_config_label_144_2065_5032_40",
 *           "text": "Factory Reset"
 *         },
 *         "status": "synced",
 *         "value": 0,
 *         "valueDefault": 0,
 *         "valueMax": 2,
 *         "valueMin": 0,
 *         "valueType": "int"
 *       },
 *       {
 *         "_id": "673b9466f5fad215178069e4",
 *         "description": {
 *           "lang_tag": "zwave_config_descr_144_2065_5032_4",
 *           "text": "For Touch only"
 *         },
 *         "deviceId": "673b9465f5fad215178069cc",
 *         "hasSetter": true,
 *         "info": {
 *           "zwave.parameter_number": 4,
 *           "zwave.parameter_size": 1
 *         },
 *         "label": {
 *           "lang_tag": "zwave_config_label_144_2065_5032_4",
 *           "text": "Secure Screen (touch)"
 *         },
 *         "status": "synced",
 *         "value": 1,
 *         "valueDefault": 1,
 *         "valueMax": 1,
 *         "valueMin": 0,
 *         "valueType": "int"
 *       },
 *       {
 *         "_id": "673b9466f5fad215178069df",
 *         "description": {
 *           "text": "Represents list of supported zwave association groups"
 *         },
 *         "deviceId": "673b9465f5fad215178069cc",
 *         "elementType": "association_group",
 *         "hasSetter": false,
 *         "label": {
 *           "text": "Zwave Association Groups"
 *         },
 *         "value": {
 *           "0_1": {
 *             "channel": 0,
 *             "commands": [
 *               "BATTERY_REPORT",
 *               "DOOR_LOCK_OPERATION_REPORT",
 *               "DEVICE_RESET_LOCALLY_NOTIFICATION",
 *               "NOTIFICATION_REPORT",
 *               "INDICATOR_REPORT"
 *             ],
 *             "maxAssociationsNumber": 5,
 *             "name": "Lifeline",
 *             "profile": "General:Lifeline"
 *           }
 *         },
 *         "valueType": "dictionary"
 *       },
 *       {
 *         "_id": "673b9466f5fad215178069e1",
 *         "description": {
 *           "lang_tag": "zwave_config_descr_144_2065_5032_1",
 *           "text": "Status LED On/Off"
 *         },
 *         "deviceId": "673b9465f5fad215178069cc",
 *         "hasSetter": true,
 *         "info": {
 *           "zwave.parameter_number": 1,
 *           "zwave.parameter_size": 1
 *         },
 *         "label": {
 *           "lang_tag": "zwave_config_label_144_2065_5032_1",
 *           "text": "Status LED"
 *         },
 *         "status": "synced",
 *         "value": 1,
 *         "valueDefault": 1,
 *         "valueMax": 1,
 *         "valueMin": 0,
 *         "valueType": "int"
 *       },
 *       {
 *         "_id": "673b9466f5fad215178069ea",
 *         "description": {
 *           "text": "Time in seconds before door lock will automatically locked after timed operation"
 *         },
 *         "deviceId": "673b9465f5fad215178069cc",
 *         "label": {
 *           "text": "Lock timeout"
 *         },
 *         "status": "synced",
 *         "value": 0,
 *         "valueType": "int"
 *       },
 *       {
 *         "_id": "673b9466f5fad215178069e9",
 *         "description": {
 *           "text": "Are timed operations enabled"
 *         },
 *         "deviceId": "673b9465f5fad215178069cc",
 *         "label": {
 *           "text": "Are timed operations enabled"
 *         },
 *         "status": "synced",
 *         "value": false,
 *         "valueType": "bool"
 *       },
 *       {
 *         "_id": "673b9466f5fad215178069e2",
 *         "description": {
 *           "lang_tag": "zwave_config_descr_144_2065_5032_2",
 *           "text": "Sound On/Off"
 *         },
 *         "deviceId": "673b9465f5fad215178069cc",
 *         "hasSetter": true,
 *         "info": {
 *           "zwave.parameter_number": 2,
 *           "zwave.parameter_size": 1
 *         },
 *         "label": {
 *           "lang_tag": "zwave_config_label_144_2065_5032_2",
 *           "text": "Sound Control"
 *         },
 *         "status": "synced",
 *         "value": 1,
 *         "valueDefault": 1,
 *         "valueMax": 1,
 *         "valueMin": 0,
 *         "valueType": "int"
 *       },
 *       {
 *         "_id": "673b9466f5fad215178069eb",
 *         "description": {
 *           "text": "Time in seconds before door lock will automatically locked after any unlock operation"
 *         },
 *         "deviceId": "673b9465f5fad215178069cc",
 *         "label": {
 *           "text": "Auto relock timeout"
 *         },
 *         "status": "synced",
 *         "value": 30,
 *         "valueType": "int"
 *       },
 *       {
 *         "_id": "673b9466f5fad215178069de",
 *         "description": {
 *           "text": "Rediscover (repeat interview) device"
 *         },
 *         "deviceId": "673b9465f5fad215178069cc",
 *         "label": {
 *           "text": "Rediscover device"
 *         },
 *         "status": "synced",
 *         "value": {
 *           "lang_tag": "rediscover_tag",
 *           "text": "Rediscover"
 *         },
 *         "valueType": "action"
 *       },
 *       {
 *         "_id": "673b9466f5fad215178069e5",
 *         "description": {
 *           "lang_tag": "zwave_config_descr_144_2065_5032_10",
 *           "text": "Perform Handing Operation"
 *         },
 *         "deviceId": "673b9465f5fad215178069cc",
 *         "hasSetter": true,
 *         "info": {
 *           "zwave.parameter_number": 10,
 *           "zwave.parameter_size": 1
 *         },
 *         "label": {
 *           "lang_tag": "zwave_config_label_144_2065_5032_10",
 *           "text": "Handing Control"
 *         },
 *         "status": "synced",
 *         "value": 2,
 *         "valueDefault": 1,
 *         "valueMax": 2,
 *         "valueMin": 0,
 *         "valueType": "int"
 *       },
 *       {
 *         "_id": "673b9466f5fad215178069e0",
 *         "description": {
 *           "text": "Setting to control device associations"
 *         },
 *         "deviceId": "673b9465f5fad215178069cc",
 *         "elementType": "array.association",
 *         "label": {
 *           "text": "Zwave Associations"
 *         },
 *         "targetChannelSupported": true,
 *         "value": {
 *           "0_1": [
 *             {
 *               "targetChannel": 0,
 *               "targetNode": 1
 *             }
 *           ]
 *         },
 *         "valueType": "dictionary"
 *       },
 *       {
 *         "_id": "673b9466f5fad215178069e7",
 *         "description": {
 *           "lang_tag": "zwave_config_descr_144_2065_5032_46",
 *           "text": "Reads last Motor Load Value"
 *         },
 *         "deviceId": "673b9465f5fad215178069cc",
 *         "hasSetter": true,
 *         "info": {
 *           "zwave.parameter_number": 46,
 *           "zwave.parameter_size": 4
 *         },
 *         "label": {
 *           "lang_tag": "zwave_config_label_144_2065_5032_46",
 *           "text": "Motor Load Value"
 *         },
 *         "status": "synced",
 *         "value": 1048803,
 *         "valueDefault": 0,
 *         "valueMax": 16777215,
 *         "valueMin": 0,
 *         "valueType": "int"
 *       },
 *       {
 *         "_id": "673b9466f5fad215178069e3",
 *         "description": {
 *           "lang_tag": "zwave_config_descr_144_2065_5032_3",
 *           "text": "Enable/Disable Local Cfg Control"
 *         },
 *         "deviceId": "673b9465f5fad215178069cc",
 *         "hasSetter": true,
 *         "info": {
 *           "zwave.parameter_number": 3,
 *           "zwave.parameter_size": 1
 *         },
 *         "label": {
 *           "lang_tag": "zwave_config_label_144_2065_5032_3",
 *           "text": "Program Button Control"
 *         },
 *         "status": "synced",
 *         "value": 1,
 *         "valueDefault": 1,
 *         "valueMax": 1,
 *         "valueMin": 0,
 *         "valueType": "int"
 *       }
 *     ]
 *   },
 *   "sender": {
 *     "conn_id": "cda84011-d1f7-476e-907d-764d03b42413",
 *     "type": "ui"
 *   }
 * }
 */

export interface DeviceSetting {
  _id: string;
  description: {
    text: string;
  };
  deviceId: string;
  label: {
    text: string;
  };
  status: string;
  value: any;
  valueType: string;
}

export enum BroadcastMsgSubclass {
  DictionaryUpdated = 'hub.item.dictionary.updated'
}

export interface BroadcastItem {
  id: string;
  msg_id?: string;
  msg_subclass?: string;
  result: any;
}

export interface UserCodeKeyValue {
  key: string;
  value: string;
  name: string;
}
